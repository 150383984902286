<template>
  <v-row no-gutters>

    <!-- Select  -->
    <v-col cols="12">
      <SelectMaterial
        :value="form.material"
        @input="onInputMaterial"
      />
    </v-col>

    <!-- Percentage -->
    <v-col cols="12" class="mt-1">
      <v-slider
        :label="$t('common.rawMaterials.percentage')"
        v-model="form.percentage"
        step="0.01"
        ticks="always"
        tick-size="4"
        min="0"
        max="1"
        hide-details
        @change="$emit('updated', form)"
      />
      <div class="title text-center">{{ (computedPercentage*100).toFixed(2) }}%</div>
    </v-col>
  </v-row>
</template>

<script>
import formatterMixin from "../../mixins/formatter"

export default {
  name: 'ComponentMaterialInput',
  components: {
    SelectMaterial: () => import("@/components/Common/Inputs/SelectMaterial")
  },
  props: {
    material: { type: String, default: null },
    percentage: { type: Number, default: 0 }
  },

  mixins: [formatterMixin],
  data() {
    return {
      form: {
        material: null,
        percentage: 0
      }
    }
  },

  created () {
    this.form.material = this.material;
    this.form.percentage = this.percentage;
  },

  methods: {
    onInputMaterial(val) {
      this.form.material = val;
      this.$emit("updated", this.form);
    }
  },
  computed: {
    computedPercentage() {
      if (this.form.percentage)
        return this.formatterRound(this.form.percentage, 2);
      return 0;
    }
  }
}
</script>

<style scoped>
.firstLetterUpper::first-letter {
  text-transform: uppercase !important;
}
</style>
